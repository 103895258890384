import {
    BOARD_STRUCTURE,
    GAME_ALLOW_TO_FINISH_MOVEMENT,
    GAME_DIALOGBOX,
    GAME_DIALOGBOX_RESPONSE,
    GAME_FINISH_MOVEMENT,
    GAME_FULL_STATUS,
    GAME_PLAYER_TOKEN,
    GAME_ROLL_DICES,
    GAME_ROLL_DICES_CONFIRM,
    GAME_ROLL_DICES_RESULT,
    GAME_START, LEAVE_GAME,
    SET_EDITING, SET_FIELD_CAPTION, SET_PLAYER_GAME_RESULT,
} from '../actions';
import {DialogBoxButtonAction, GamePlayer, GameState, PlayerColors} from "../../types";
import {playersColors} from "../../helpers/constants";


const INIT_STATE: GameState = {
    editing: null,
    fieldCaption: null,
    players: [],
    playersColors: {},
    playerGameResult: null,
    board: null,
    loadingBoard: true,
    processing: false,
    dice1: null,
    dice2: null,
    loadingGameStatus: true,
    status: null,
    player_token: null,
    game_token: null,
    owner_player_token: null,
    property_relations: null,
    property: [],
    buildings: null,
    pledges: null,
    dialogBox: null,
    current_player_movement_token: null,
    canRollDice: false,
    allowFinishMovement: false,
}



const gameReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GAME_FULL_STATUS:
            const colors: PlayerColors = {};

            action.status.players.forEach((p: GamePlayer, i: number) => {
                colors[p.player_token] = playersColors[i];
            })

            return {
                ...state,
                players: [
                    ...action.status.players
                ],
                playersColors: {
                    ...colors
                },
                status: action.status.status,
                game_token: action.status.game_token,
                owner_player_token: action.status.owner_player_token,
                property_relations: action.status.property_relations,
                buildings: action.status.buildings,
                pledges: action.status.pledges,
                current_player_movement_token: action.status.current_player_movement_token,
                loadingGameStatus: false,
                processing: false,
                property: action.status.property,
            };

        case GAME_START:
            return {
                ...state,
                processing: true,
            }

        case LEAVE_GAME:
            return {
                ...state,
                processing: true,
            }

        case SET_EDITING:
            return {
                ...state,
                editing: action.editing,
            };

        case GAME_PLAYER_TOKEN:
            return {
                ...state,
                player_token: action.token,
            }

        case SET_FIELD_CAPTION:
            return {
                ...state,
                fieldCaption: action.id,
            };

        case BOARD_STRUCTURE:
            return {
                ...state,
                board: {
                    ...action.board,
                },
                loadingBoard: false,
            }

        case GAME_ROLL_DICES_CONFIRM:
            return {
                ...state,
                processing: true,
                canRollDice: false,
            }

        case GAME_ROLL_DICES_RESULT:
            return {
                ...state,
                editing: null,
                dice1: action.dices.dice1,
                dice2: action.dices.dice2,
                processing: false,
            }

        case GAME_ROLL_DICES:
            return {
                ...state,
                processing: true,
                canRollDice: true,
            }

        case SET_PLAYER_GAME_RESULT:
            return {
                ...state,
                playerGameResult: action.result,
            }

        case GAME_DIALOGBOX:
            return {
                ...state,
                processing: false,
                dialogBox: action.dialogBox,
            }

        case GAME_DIALOGBOX_RESPONSE:
            return {
                ...state,
                processing: true,
                editing: null,
                dialogBox: null,
                players: [
                    ...state.players.map((p) => ({
                        ...p,
                        can_edit_property: action.button_action === DialogBoxButtonAction.END_MOVE ? false : p.can_edit_property
                    })),

                ]
            }

        case GAME_FINISH_MOVEMENT:
            return {
                ...state,
                editing: null,
                allowFinishMovement: false,
            };

        case GAME_ALLOW_TO_FINISH_MOVEMENT:
            return {
                ...state,
                allowFinishMovement: true,
            }


        default:
            return state;
    }
};

export default gameReducer;
