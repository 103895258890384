import React, {useEffect, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import './assets/styles/App.scss';
import ProtectedRoute from './helpers/ProtectedRoute';
import {rootPath} from "./helpers/constants";
import {RootState} from "./redux/store";
import ErrorPage from "./views/ErrorPage";
import {startConnection} from "./redux/app/actions";
import AppLayout from "./views/app/AppLayout";
import {selectConnected, selectLoading, selectSessId, selectUsername} from "./helpers/selectors";

const ViewApp = React.lazy(() => import('./views/app'));
const ViewLogin = React.lazy(() => import('./views/login'));
const ViewLobby = React.lazy(() => import('./views/app/lobby/lobby'));
const ViewGame = React.lazy(() => import('./views/app/game/Game'));


const AppLay = () => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <Outlet />
    </React.Suspense>
);

const App = () => {
    const dispatch = useDispatch();
    const location = useSelector((state: RootState) => state.app.location);
    const sessId = useSelector(selectSessId)
    const username = useSelector(selectUsername)
    const connected = useSelector(selectConnected);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(startConnection());
    }, []);


    const navigate = useNavigate();

    useEffect(() => {
        if (location) {
            navigate(location); // Nawigacja na podstawie zmienionego stanu
        }
    }, [location, navigate]);


    return (
        <AppLayout>
            <Routes>
                <Route path={rootPath} element={<ProtectedRoute sessId={sessId} username={username} />}>
                    <Route index element={<Navigate to="dashboard" replace />} />
                    <Route
                        path="dashboard"
                        element={<ViewLobby/>}
                    />
                    <Route
                        path="game"
                        element={<ViewGame/>}
                    />
                    <Route
                        path="*"
                        element={<ErrorPage msg="guwno" />}
                    />
                </Route>
                <Route path="/login" element={<ViewLogin  />} />
                <Route path="/error" element={<ErrorPage  />} />
                {/*<Route path="*" element={<Navigate to={rootPath} replace />} />*/}
            </Routes>
        </AppLayout>
    );
}

export default App;

