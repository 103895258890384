import {
    Board,
    DialogBox,
    GameDicesResult,
    GameFullStatus,
    GameModel,
    PlayerGameResult,
    WSMessage,
    WsMessageItemType
} from "../types";
import store from "../redux/store";
import {setError, setToken, setUsernameError, setUsernameSuccess} from "../redux/actions";
import {gameGetListSuccess} from "../redux/lobby/actions";
import {
    boardStructure,
    gameAllowToFinishMovement,
    gameDialogBox,
    gameFullStatus,
    gamePlayerToken,
    gameRollDices,
    gameRollDicesResult, setPlayerGameResult
} from "../redux/game/actions";
import {setLocation} from "../redux/app/actions";

export default function parseMessage(m: WSMessage) {
    console.log(m);

    if(m.items && m.items.length > 0) {
        m.items.forEach((item) => {
            switch(item.type) {
                case WsMessageItemType.LOCATION:
                    switch(item?.data) {
                        case 'LOBBY':
                            // history.push(`${rootPath}`)
                            console.log('dawaj lobby');
                            store.dispatch(setLocation(`/dashboard`));
                            break;

                        case 'GAME':
                            console.log('dawaj gre');
                            // history.push(`${rootPath}/game`)
                            store.dispatch(setLocation(`/game`));
                            break;

                        case 'LOGIN':
                            // history.push(`${rootPath}`)
                            console.log('dawaj login');
                            store.dispatch(setLocation(`/login`));
                            break;

                        default:
                            break;
                    }
                    break;
                case WsMessageItemType.TOKEN:
                    if(item?.data && typeof item?.data === 'string') {
                        sessionStorage.setItem('token', item.data)
                        store.dispatch(setToken(item.data))
                    }
                    break;

                case WsMessageItemType.USERNAME:
                    store.dispatch(setUsernameSuccess(item.data))
                    break;

                case WsMessageItemType.USERNAME_EXIST:
                    store.dispatch(setUsernameError('Nazwa jest już zajęta.'))
                    break;

                case WsMessageItemType.GAME_LIST:
                    const games = item.data as GameModel[];
                    store.dispatch(gameGetListSuccess([...games]));
                    break;

                case WsMessageItemType.BOARD_STRUCTURE:
                    const board = item.data as Board;
                    store.dispatch(boardStructure(board));
                    break;

                case WsMessageItemType.GAME_FULL_STATUS:
                    const game = item.data as GameFullStatus;
                    store.dispatch(gameFullStatus(game));
                    break;

                case WsMessageItemType.GAME_ROLL_DICES_RESULT:
                    const dices = item.data as GameDicesResult;
                    store.dispatch(gameRollDicesResult(dices));
                    break;

                case WsMessageItemType.GAME_ROLL_DICES:
                    store.dispatch(gameRollDices());
                    break;

                case WsMessageItemType.GAME_PLAYER_TOKEN:
                    const token = item.data as string;
                    store.dispatch(gamePlayerToken(token));
                    break;

                case WsMessageItemType.GAME_DIALOGBOX:
                    const dialogBox = item.data as DialogBox;
                    store.dispatch(gameDialogBox(dialogBox));
                    break;

                case WsMessageItemType.GAME_ALLOW_TO_FINISH_MOVEMENT:
                    store.dispatch(gameAllowToFinishMovement());
                    break;

                case WsMessageItemType.ERROR:
                    const error = item.data as string;
                    store.dispatch(setError(error));
                    break;

                case WsMessageItemType.GAME_WIN:
                    store.dispatch(setPlayerGameResult(PlayerGameResult.GAME_WIN))
                    break;

                case WsMessageItemType.GAME_LOST:
                    store.dispatch(setPlayerGameResult(PlayerGameResult.GAME_LOST))
                    break;

                default:
                    break;
            }
        });
    }


}
