import React from 'react';
import logo from '../assets/images/logo.svg';


interface Props {
    msg?: string
}
const ErrorPage = ({ msg }: Props ) => {
    return (<div className="ErrorPage">
        <img src={logo} className="Login__logo" alt="COSMOpoly" />
        {msg ? msg : 'Błąd'}
    </div>)
}

export default ErrorPage;
