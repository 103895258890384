import {Action} from "redux";
import {GAME_START} from "../redux/actions";



export interface GetGameListSuccessAction extends Action {
    history: History
}

export interface GameDialogBoxResponseAction extends Action {
    dialogbox_token: DialogBox['token']
    button_action: string,
}

export interface SendMessageAction extends Action {
    data: WSMessageData
}

export interface IncomingMessageAction extends Action {
    data: WSMessage,
    history: History
}

export interface SetUserNameAction extends Action {
    value: string;
}

export interface GameJoinAsPlayerAction extends Action {
    gameToken: string;
}

export interface ErrorCodes {
    [key: number]: string,
}

export enum ConnectionStatus {
    'Connected' = 1
}

export interface Connection {
    status: ConnectionStatus,
    sock: WebSocket
}

export interface WSMessageData {
    action: string,
    data?: any
}

export enum WsMessageStatus {
    IN_GAME = "IN_GAME",
    SPECTATOR = "SPECTATOR",
    IDLE = "IDLE",
}

export enum WsMessageLocation {
    LOBBY = "LOBBY",
    GAME = "GAME"
}

export interface WsMessageItem {
    type: WsMessageItemType,
    data: any
}

export enum GameStatus {
    WAITING = "WAITING",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    STOPPED = "STOPPED",
}

export interface GameModel {
    game_token: string,
    status: GameStatus,
    number: number,
    min_players: number,
    max_players: number,
    players: string[],
}

export enum BuildingType {
    HOTEL = "HOTEL",
    HOUSE = "HOUSE",
}

export interface Building {
    type: BuildingType,
    amount: number
}

export interface GameBuildings {
    [key: string]: Building
}

export interface PropertyRelations {
    [key: string]: string
}

export interface GamePlayer {
    player_token: string,
    inactive: boolean, // True when player has left the table
    username: string, // User login
    balance: number,
    avoid_prison_cards_amount: number,
    position: number|null, // Current field ID
    prison: boolean,
    time_in_prison_queue: null,
    lose: boolean,
    can_edit_property: false,
}

export interface Property {
    id: number
    owner_token: string
    is_pledged: boolean,
    houses: number
    hotels: number
}

export interface GameFullStatus {
    status: GameStatus,
    player_token: string,
    game_token: string,
    players: GamePlayer[],
    owner_player_token: string,
    property_relations: PropertyRelations,
    buildings: GameBuildings,
    pledges: number[],
    current_player_movement_token: string|null,
    property: Property[],
}

export interface GameDicesResult {
    dice1: number;
    dice2: number;
}

export interface GroupsNames {
    [key: string]: string,
}

export type FieldsNames = Array<string>
export interface FieldProps {
    name: string,
    value?: number
}

export interface PlayerColors {
    [key: GamePlayer['player_token']]: string,
}

export enum PlayerGameResult {
    GAME_LOST = 'GAME_LOST',
    GAME_WIN = 'GAME_WIN'
}
export enum EditingMode {
    BUY_BUILDING,
    SELL_BUILDING,
    PLEDGE,
    UNPLEDGE
}

export interface GameState {
    editing: EditingMode|null,
    fieldCaption: number|null,
    players: GamePlayer[],
    playersColors: PlayerColors,
    board: Board|null,
    dice1: number|null,
    dice2: number|null,
    playerGameResult: PlayerGameResult|null,
    canRollDice: boolean,
    processing: boolean,
    loadingGameStatus: boolean,
    loadingBoard: boolean,
    dialogBox: DialogBox|null,
    status: GameFullStatus['status'] | null,
    player_token: GameFullStatus['player_token'] | null,
    game_token: GameFullStatus['game_token'] | null,
    owner_player_token: GameFullStatus['owner_player_token'] | null,
    property_relations: GameFullStatus['property_relations'] | null,
    buildings: GameFullStatus['buildings'] | null,
    pledges: GameFullStatus['pledges'] | null,
    allowFinishMovement: boolean;
    current_player_movement_token: GameFullStatus['current_player_movement_token'],
    property: Property[],
}

export enum StructureFieldType {
    START = "START",
    STANDARD = "STANDARD",
    BLUE_CHANCE = "BLUE_CHANCE",
    RED_CHANCE = "RED_CHANCE",
    PRISON = "PRISON",
    SPECIAL = "SPECIAL",
    DIRECTIONAL = "DIRECTIONAL",
    GO_TO_PRISON = "GO_TO_PRISON",
    PAYMENT = "PAYMENT",
}

export interface StructureFieldPayment {
    empty?: number,
    houses?: {
        [key: number]: number,
    },
    hotel?: number
    directionals?: {
        [key: string]: number,
    }
}

export interface StructureField {
    name: string,
    type:StructureFieldType,
    group_id?: number,
    price?: number,
    pledge?: number,
    house_price?: number,
    hotel_price?: number,
    payment?: number,
    payments?: StructureFieldPayment,

}

export interface FieldColors {
    [key: number]: string,
}

export interface Structure {
    [key: string]: StructureField,
}

export interface Board {
    name: string;
    start_balance: number;
    start_position: number;
    movement_through_start_payment: number,
    number_of_turns_in_prison: number,
    prison_position: number,
    currency: string,
    groups_names: GroupsNames,
    structure: Structure
}

export interface DialogBox {
    token: string,
    description: string,
    buttons: DialogBoxButton[],
}

export enum DialogBoxButtonAction {
    AVOID_PRISON_CARD="AVOID_PRISON_CARD",
    GO_TO_PRISON="GO_TO_PRISON",
    GO="GO",
    PAY="PAY",
    BUY_PROPERTY_NO="BUY_PROPERTY_NO",
    BUY_PROPERTY_YES="BUY_PROPERTY_YES",
    TAKE_ANOTHER_CARD="TAKE_ANOTHER_CARD",
    ROLL_DICES="ROLL_DICES",
    END_MOVE="END_MOVE",
}
export interface DialogBoxButton {
    label: string
    action: DialogBoxButtonAction
    inactive: boolean,
}

export enum WsMessageItemType {
    LOCATION = 'LOCATION',
    TOKEN = "TOKEN",
    USERNAME = "USERNAME",
    USERNAME_EXIST = "USERNAME_EXIST",
    USERNAME_UPDATED = "USERNAME_UPDATED",
    GAME_LIST = "GAME_LIST",
    ERROR = "ERROR",
    PLAYERS_LIST = "PLAYERS_LIST",
    BOARD_LIST = "BOARD_LIST",
    GAME_FULL_STATUS = "GAME_FULL_STATUS",
    BOARD_STRUCTURE = "BOARD_STRUCTURE",
    GAME_ROLL_DICES = "GAME_ROLL_DICES",
    GAME_ROLL_DICES_RESULT = "GAME_ROLL_DICES_RESULT",
    GAME_DIALOGBOX = "GAME_DIALOGBOX",
    GAME_PLAYER_TOKEN = "GAME_PLAYER_TOKEN",
    GAME_ALLOW_TO_FINISH_MOVEMENT = "GAME_FINISH_MOVEMENT",
    GAME_WIN = 'GAME_WIN',
    GAME_LOST = 'GAME_LOST',
}

export interface WSMessage {
    status: WsMessageStatus,
    location: WsMessageLocation,
    items : WsMessageItem[]
}
