import Field from "../views/app/game/Field";
import {FieldColors} from "../types";

export const rootPath = '/';

export const houseColor = '#548346';
export const hotelColor = '#e03131';

export const fieldsColors: FieldColors  = {
    2: '#8c6239',
    4: '#8c6239',

    7: '#00a651',
    9: '#00a651',
    10: '#00a651',

    12: '#f26d7d',
    14: '#f26d7d',
    15: '#f26d7d',

    17: '#f26522',
    19: '#f26522',
    20: '#f26522',

    22: '#ed1c24',
    24: '#ed1c24',
    25: '#ed1c24',

    27: '#8842b4',
    28: '#8842b4',
    30: '#8842b4',

    32: '#fff568',
    33: '#fff568',
    35: '#fff568',

    38: '#0072bc',
    40: '#0072bc',

    51: '#da5c30',
    52: '#9f5be5',
    53: '#97be20',
}

export const playersColors = [
    '#2583ff',
    '#33cc00',
    '#ff1111',
    '#ffb01d',
]
