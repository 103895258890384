import React, {PropsWithChildren} from 'react';
import {Route, Navigate, useNavigate, Outlet} from 'react-router-dom';


interface Props extends PropsWithChildren {
    sessId: string|null;
    username: string|null;
}

const ProtectedRoute = ({
    sessId,
    username,
    children
}: Props) => {
    console.log('sessOd');
    console.log(sessId);
    console.log(username)
    if(!sessId || !username)
        return <Navigate
            to="/login"
            replace
        />

    return <Outlet />
}


export default ProtectedRoute;
