import {RootState} from "../redux/store";
import {GameState} from "../types";

export const selectSessId = (state: RootState) => state.app.sessId
export const selectUsername = (state: RootState) => state.app.username;
export const selectConnected = (state: RootState) => state.app.connected;
export const selectLoading = (state: RootState) => state.app.loading;

export const selectGameStatus = (state: RootState) => state.game.status;
export const selectDice1 = (state: RootState)  => state.game.dice1;
export const selectDice2 = (state: RootState)  => state.game.dice2;

export const selectPlayers = (state: RootState) => state.game.players;
export const selectPlayersColors = (state: RootState) => state.game.playersColors;
export const selectProperties = (state: RootState) => state.game.property;
export const selectPlayerToken = (state: RootState) => state.game.player_token;
export const selectEditing = (state: RootState) => state.game.editing;


export const selectCurrency = (state: RootState) => state.game.board.currency;
export const selectBoard = (state: RootState) => state.game.board
export const selectStructure = (state: RootState) => state.game.board.structure;
export const selectFieldCaption = (state: RootState) => state.game.fieldCaption;
