import React, {PropsWithChildren} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import Loading from "../../components/loading";
import Modal from '../../components/Modal';
import {setError} from "../../redux/actions";

const AppLayout = ({children}: PropsWithChildren) => {
    const dispatch = useDispatch();
    const connectionLost = useSelector((state: RootState) => state.app.connectionLost);
    const connected = useSelector((state: RootState) => state.app.connected);
    const loading = useSelector((state: RootState) => state.app.loading);
    const usernameLoading = useSelector((state: RootState) => state.app.usernameLoading);
    const error = useSelector((state: RootState) => state.app.error);

    const clearModal = () => {
        dispatch(setError(''));
    }
    return (


        <div className="App app-container d-flex h-100 p-5">
            {!connected || loading || usernameLoading ? <Loading className="m-auto" /> : children}
            {
                error &&<Modal onClose={clearModal}>
                    {error}
              </Modal>
            }
        </div>
    )
}

export default AppLayout
