import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter} from "react-router-dom";
import history from './history';
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import History from "./history";

const rootHTML = document.getElementById("root");


if(rootHTML) {
    const root = createRoot(rootHTML);
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
